import {
  HttpClient,
  HttpErrorResponse,
  HttpParams,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { EnvService } from '.';

@Injectable({
  providedIn: 'root',
})
export class TtHttpClientService {
  baseUrl: string;
  constructor(
    private httpClient: HttpClient,
    private envService: EnvService
  ) {
    this.baseUrl = envService.webAPIRoot;
  }

  post<T>(url: string, params: HttpParams) {
    const paramsObject = params.keys().reduce((object: any, key) => {
      object[key] = params.get(key);
      return object;
    }, {});

    return this.httpClient.post<T>(this.baseUrl + url, paramsObject).pipe(
      catchError((err: HttpErrorResponse) => {
        throw this.onError(err);
      })
    );
  }

  postCustomObject<T>(url: string, params: any) {
    return this.httpClient.post<T>(this.baseUrl + url, params).pipe(
      catchError((err: HttpErrorResponse) => {
        return of(err);
      })
    );
  }

  // notice the <T>, making the method generic
  get<T>(url: string, params?: HttpParams): Observable<T> {
    return this.httpClient.get<T>(this.baseUrl + url, { params }).pipe(
      catchError((err: any) => {
        throw this.onError(err);
      })
    );
  }

  onError(err: HttpErrorResponse) {
    if (err.error instanceof Error) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', err.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${err.status}, body was: ${err.error}`
      );
    }

    // ...optionally return a default fallback value so app can continue (pick one)
    // which could be a default value
    // return Observable.of<any>({my: "default value..."});
    // or simply an empty observable
    return of<any>(err);
  }
}
