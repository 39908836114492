import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { EnvService, TtHttpClientService } from 'src/app/core/services';

@Injectable({
  providedIn: 'root',
})
export class SupplierOffersService {
  private uid = 'SYSTEM';
  constructor(
    private http: TtHttpClientService,
    private envService: EnvService
  ) {}

  validateTokenForCarrierQuoteRequest(token: string) {
    return this.http.get(
      this.envService.api.supplier.validateTokenForCarrierQuoteRequest +
        '?token=' +
        token
    );
  }

  createShipmentLoadOffer(params: any) {
    return this.http.postCustomObject(
      this.envService.api.shipment.createShipmentLoadOfferFromSupplierWebsite,
      params
    );
  }
}
