<div
  role="alert"
  *ngIf="isLoading"
  class="tt-spinner"
  style="position: {{ position }};">
  <div class="spinner {{ size }}">
    <div class="dots">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
    <div class="text">Loading. Please wait...</div>
  </div>
</div>
