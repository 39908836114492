import { AppComponent } from './app/app.component';
import { bootstrapApplication } from '@angular/platform-browser';
import { provideRouter } from '@angular/router';
import { APP_ROUTES } from './app/app.routes';
import { HttpClient, provideHttpClient } from '@angular/common/http';
import { provideAnimations } from '@angular/platform-browser/animations';
import { LicenseManager } from 'ag-grid-enterprise';
import { FlatpickrModule } from 'angularx-flatpickr';
import {
  TranslateModule,
  TranslateLoader,
  TranslateService,
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import {
  LocalizeParser,
  LocalizeRouterModule,
  LocalizeRouterService,
  LocalizeRouterSettings,
} from '@gilsdav/ngx-translate-router';
import { LocalizeRouterHttpLoader } from '@gilsdav/ngx-translate-router-http-loader';
import { LOCALE_ID, importProvidersFrom } from '@angular/core';

LicenseManager.setLicenseKey(
  'CompanyName=Traffic Tech,LicensedGroup=Traffic Tech,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=5,LicensedProductionInstancesCount=2,AssetReference=AG-037894,SupportServicesEnd=23_March_2024_[v2]_MTcxMTE1MjAwMDAwMA==be9fd35cd2530fada4d32ef048ad88d5'
);
import localeFr from '@angular/common/locales/fr';
import localeEs from '@angular/common/locales/es';
import { Location, registerLocaleData } from '@angular/common';
import { TranslateRouterLinkPipe } from './app/core/pipes';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';

bootstrapApplication(AppComponent, {
  providers: [
    provideRouter(APP_ROUTES),
    provideHttpClient(),
    provideAnimations(),
    TranslateRouterLinkPipe,
    importProvidersFrom(FlatpickrModule.forRoot()),
    importProvidersFrom(
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: httpTranslateLoader,
          deps: [HttpClient],
        },
      })
    ),
    importProvidersFrom(
      LocalizeRouterModule.forRoot(APP_ROUTES, {
        parser: {
          provide: LocalizeParser,
          useFactory: HttpLoaderFactory,
          deps: [
            TranslateService,
            Location,
            LocalizeRouterSettings,
            HttpClient,
          ],
        },
        defaultLangFunction: getDefaultLang,
      })
    ),
    {
      provide: LOCALE_ID,
      deps: [LocalizeRouterService],
      useFactory: GlobalLocaleFactory,
    },
  ],
}).catch(err => console.error(err));

export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
export function HttpLoaderFactory(
  translate: TranslateService,
  location: Location,
  settings: LocalizeRouterSettings,
  http: HttpClient
) {
  return new LocalizeRouterHttpLoader(
    translate,
    location,
    settings,
    http,
    '../assets/i18n/locales.json'
  );
}
export function GlobalLocaleFactory(localizeService: any) {
  registerLocaleData(localeFr);
  registerLocaleData(localeEs);
  return localizeService.parser.currentLang;
}
export function getDefaultLang(
  languages: string[],
  cachedLang?: string,
  browserLang?: string
) {
  return 'en';
}
