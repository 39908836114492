import { Routes } from '@angular/router';
import { SupplierOffersComponent } from './features/supplier-offers/supplier-offers.component';

export const APP_ROUTES: Routes = [
  {
    path: '',
    data: { titleRef: 'Supplier Offers' },
    loadComponent: () =>
      import('./features/supplier-offers/supplier-offers.component').then(
        mod => mod.SupplierOffersComponent
      ),
  },
  { path: '**', component: SupplierOffersComponent }, // Wildcard route for a 404 page
];
