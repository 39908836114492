import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class EnvService {
  get api() {
    return api;
  }

  get webAPIRoot() {
    return environment.webAPIRoot;
  }
}
export const api = {
  shipment: {
    createShipmentLoadOfferFromSupplierWebsite:
      '/Shipment/CreateShipmentLoadOfferFromSupplierWebsite',
  },
  supplier: {
    validateTokenForCarrierQuoteRequest:
      '/Supplier/ValidateTokenForCarrierQuoteRequest',
  },
};
