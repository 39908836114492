<app-tt-spinner [isLoading]="isLoading"></app-tt-spinner>

<div *ngIf="!isLoading" class="supplier-offers">
  <ng-container *ngIf="!formSubmitted && !invalidShipmentToken && !submitError">
    <mat-label class="form_instruction">{{
      'SUPPLIER_OFFERS.TEXT_FORM_SUBMIT_INSTRUCTION' | translate
    }}</mat-label>
    <ng-container *ngIf="shipmentSummary">
      <div class="supplier-offers__content row">
        <div class="col-12 supplier-offers__shp-details">
          <span>
            <!-- SHIPMENT DETAILS -->
            <div
              class="supplier-offers__shp-details__equipment"
              *ngIf="shipmentSummary as details">
              <span class="supplier-offers__shp-details__size">{{
                details.equipmentType + ', ' + details.shipmentSize
              }}</span>
              <span class="supplier-offers__shp-details__weight">
                {{
                  ('SUPPLIER_OFFERS.TEXT_TOTAL_WEIGHT' | translate) +
                    ': ' +
                    details.totalWeightInLbs
                }}
                lbs
              </span>
            </div>
            <div class="supplier-offers__shp-details__tasks">
              <mat-accordion *ngFor="let task of shipmentSummary.tasks">
                <mat-expansion-panel
                  [expanded]="true"
                  hideToggle="true"
                  class="mat-elevation-z0 disabled-pointer">
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      <div class="label-value">
                        <div>{{ task.taskType | uppercase }} -</div>
                        <div>
                          <div>
                            {{ task.location }}
                            &nbsp;&nbsp;
                            {{
                              task.serviceDateTime
                                | date
                                  : 'MMM dd, yyyy'
                                  : undefined
                                  : selectedLang
                            }}
                          </div>

                          <div class="service-type-and-hour">
                            <span>{{ 'SUPPLIER_OFFERS.TEXT_SERVICE_TYPE_' + (task.serviceType ?? 'APPT' | uppercase) | translate }}: </span>
                            <span>{{ task.serviceHours ?? ('SUPPLIER_OFFERS.TEXT_SERVICE_HOUR_TBD' | translate) }}</span>
                          </div>
                        </div>
                      </div>
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <ng-container *ngIf="task.freightItems.length > 0">
                    <div *ngFor="let item of task.freightItems">
                      {{ item }}
                    </div>
                  </ng-container>
                </mat-expansion-panel>
              </mat-accordion>
            </div></span
          >
        </div>
        <div class="col-12 supplier-offers__shp-actions">
          <form [formGroup]="supplierOfferForm">
            <!-- SUBMIT QUOTE -->
            <div class="form__quote">
              <div class="supplier-offers__shp-actions__rate-types">
                <mat-label>
                  {{
                    'SUPPLIER_OFFERS.TEXT_SELECT_RATE_TYPE' | translate
                  }}: </mat-label
                >&nbsp;
                <mat-radio-group
                  class="rate-type {{ selectedLang }}"
                  formControlName="selectedRateType"
                  [(ngModel)]="selectedRateType"
                  (change)="onRateTypeChange($event.value)">
                  <mat-radio-button [value]="1">{{
                    'SUPPLIER_OFFERS.LBL_RATE_TYPE_DETAILED' | translate
                  }}</mat-radio-button>
                  <mat-radio-button [value]="2">{{
                    'SUPPLIER_OFFERS.LBL_RATE_TYPE_ALL_IN' | translate
                  }}</mat-radio-button>
                  <mat-radio-button class="radio-decline" [value]="3">{{
                    'SUPPLIER_OFFERS.LBL_RATE_TYPE_DECLINED' | translate
                  }}</mat-radio-button>
                </mat-radio-group>
              </div>
              <ng-container
                *ngIf="selectedRateType === 2 || selectedRateType === 1">
                <div class="row">
                  <ng-container *ngIf="selectedRateType === 1">
                    <div class="col-12 linehaul-amount">
                      <mat-form-field
                        [ngClass]="{ required: selectedRateType === 1 }">
                        <mat-label>{{
                          'SUPPLIER_OFFERS.LBL_LINEHAUL_RATE' | translate
                        }}</mat-label>
                        <input
                          [required]="selectedRateType === 1"
                          onlyNumbers
                          formControlName="lineHaulAmount"
                          matInput />
                      </mat-form-field>
                    </div>
                    <div
                      class="col-12 fuel-surcharge-amount {{ selectedLang }}">
                      <mat-form-field
                        [ngClass]="{ required: selectedRateType === 1 }">
                        <mat-label>{{
                          'SUPPLIER_OFFERS.LBL_FUEL_CHARGE' | translate
                        }}</mat-label>
                        <input
                          onlyNumbers
                          [required]="selectedRateType === 1"
                          formControlName="fuelSurchargeAmount"
                          matInput />
                      </mat-form-field></div
                  ></ng-container>

                  <div
                    class="col-12 all-in-price"
                    *ngIf="selectedRateType === 2">
                    <mat-form-field
                      [ngClass]="{ required: selectedRateType === 2 }">
                      <mat-label>{{
                        'SUPPLIER_OFFERS.LBL_ALL_IN_PRICE' | translate
                      }}</mat-label>
                      <input
                        [required]="selectedRateType === 2"
                        formControlName="allInAmount"
                        matInput />
                    </mat-form-field>
                  </div>
                  <div class="col-12 currency">
                    <mat-form-field appearance="outline">
                      <mat-label>{{
                        'SUPPLIER_OFFERS.LBL_CURRENCY' | translate
                      }}</mat-label>
                      <mat-select formControlName="currencyId">
                        <mat-option value="1">USD </mat-option>
                        <mat-option value="2">CAD </mat-option>
                        <mat-option value="3">MXN </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
              </ng-container>
              <div
                *ngIf="selectedRateType === 1 || selectedRateType === 2"
                class="row">
                <div class="col-12 truck-empty-location">
                  <mat-form-field
                    [ngClass]="{
                      required: selectedRateType === 1 || selectedRateType === 2
                    }"
                    appearance="fill">
                    <mat-label>{{
                      'SUPPLIER_OFFERS.LBL_TRUCK_EMPTY_LOCATION' | translate
                    }}</mat-label>
                    <input
                      [required]="
                        selectedRateType === 1 || selectedRateType === 2
                      "
                      formControlName="truckEmptyLocation"
                      matInput />
                  </mat-form-field>
                </div>
                <div class="col-12 truck-empty-time">
                  <mat-form-field
                    [ngClass]="{
                      required: selectedRateType === 1 || selectedRateType === 2
                    }">
                    <mat-label>{{
                      'SUPPLIER_OFFERS.LBL_TRUCK_EMPTY_TIME' | translate
                    }}</mat-label>
                    <input
                      matInput
                      [required]="
                        selectedRateType === 1 || selectedRateType === 2
                      "
                      formControlName="truckEmptyTime"
                      [ngxTimepicker]="timepickerWithButtons"
                      readonly />
                    <ngx-material-timepicker
                      #timepickerWithButtons
                      [theme]="darkTheme"
                      [minutesGap]="30"
                      [cancelBtnTmpl]="cancelBtn"
                      [confirmBtnTmpl]="confirmBtn"></ngx-material-timepicker>
                    <ng-template #cancelBtn>
                      <button mat-raised-button style="margin-right: 10px">
                        {{ 'SUPPLIER_OFFERS.LBL_CANCEL_BTN' | translate }}
                      </button>
                    </ng-template>
                    <ng-template #confirmBtn>
                      <button class="confirm-btn" mat-raised-button>
                        {{ 'SUPPLIER_OFFERS.LBL_CONFIRM_BTN' | translate }}
                      </button>
                    </ng-template>
                    <span
                      style="margin-right: -5px"
                      matIconSuffix
                      class="material-icons-outlined">
                      schedule
                    </span>
                  </mat-form-field>
                </div>
              </div>
              <div
                *ngIf="selectedRateType === 1 || selectedRateType === 2"
                class="form__action-btn">
                <button
                  primary
                  mat-button
                  [disabled]="!supplierOfferForm.valid"
                  (click)="event.onSupplieOfferSubmit(supplierOfferForm.value)"
                  type="button">
                  {{ 'SUPPLIER_OFFERS.LBL_SUBMIT' | translate }}
                </button>
              </div>
            </div>
            <!-- REJECT -->
            <div class="form__reject" *ngIf="selectedRateType === 3">
              <mat-label
                >{{
                  'SUPPLIER_OFFERS.TEXT_DECLINED_REASON_INSTRUCTION'
                    | translate
                }}:
              </mat-label>
              &nbsp;
              <div class="form__reject__reasons">
                <mat-radio-group
                  formControlName="declinedReasonId"
                  aria-label="Select an option">
                  <mat-radio-button value="1">{{
                    'SUPPLIER_OFFERS.LBL_DECLINED_REASON_NO_CAPACITY'
                      | translate
                  }}</mat-radio-button>
                  <mat-radio-button value="2">{{
                    'SUPPLIER_OFFERS.LBL_DECLINED_REASON_DO_NOT_SEVICE_DEST'
                      | translate
                  }}</mat-radio-button>
                </mat-radio-group>
              </div>

              <div class="form__action-btn">
                <button
                  mat-button
                  primary
                  [disabled]="!supplierOfferForm.get('declinedReasonId')?.value"
                  (click)="event.onSupplieOfferSubmit(supplierOfferForm.value)"
                  type="button">
                  {{ 'SUPPLIER_OFFERS.LBL_SUBMIT' | translate }}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </ng-container></ng-container
  >

  <div
    *ngIf="formSubmitted && !invalidShipmentToken"
    class="supplier-offers__form-summited-message">
    {{ 'SUPPLIER_OFFERS.TEXT_FORM_SUBMIT_SUCCESS_MSG' | translate }}
  </div>
  <div
    *ngIf="invalidShipmentToken"
    class="supplier-offers__invalid-shipment-token-message">
    {{ 'SUPPLIER_OFFERS.TEXT_INVALID_TOKEN' | translate }}
  </div>
  <div *ngIf="submitError" class="supplier-offers__submit-error-message">
    {{ 'SUPPLIER_OFFERS.TEXT_FORM_SUBMIT_FAIL_MSG' | translate }}
  </div>
</div>
