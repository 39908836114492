<div class="header">
  <div class="header__logo">
    <a
      [routerLink]="'/login'"
      routerLinkActive="active"
      ariaCurrentWhenActive="page">
      <img class="header__logo-image" src="assets/images/logo.jpg" alt="" />
    </a>
  </div>
  <div class="header__middle">
    <div>{{ headerService.welcomeMessage | translate }}</div>
  </div>
</div>
