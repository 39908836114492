import { Component } from '@angular/core';
import {
  Event as NavigationEvent,
  Router,
  RouterOutlet,
  RoutesRecognized,
} from '@angular/router';
import { HeaderComponent } from './features/header/header.component';
import { TtSpinnerComponent } from './share';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './features/footer/footer.component';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [
    RouterOutlet,
    HeaderComponent,
    TtSpinnerComponent,
    CommonModule,
    FooterComponent,
  ],
})
export class AppComponent {
  isLoading = false;
  constructor(
    private router: Router,
    public titleService: Title
  ) {
    this.subscribeToRouting();
  }
  private subscribeToRouting() {
    this.router.events.subscribe((event: NavigationEvent) => {
      if (event instanceof RoutesRecognized) {
        this.setDynamicPageTitle(event);
      }
    });
  }

  setDynamicPageTitle(event: RoutesRecognized) {
    let pageTitle = 'Online Forms';
    let childRoute = event.state.root.firstChild;
    let titleRef = childRoute?.data['titleRef'];
    while (!titleRef && childRoute) {
      childRoute = childRoute?.firstChild ?? null;
      titleRef = childRoute?.data['titleRef'];
    }
    if (titleRef) {
      pageTitle = 'Online Forms | ' + titleRef;
    }
    this.titleService.setTitle(pageTitle);
  }
}
