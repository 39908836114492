import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { TranslateService } from '@ngx-translate/core';
import { TranslateRouterLinkPipe } from 'src/app/core/pipes';

@Injectable({
  providedIn: 'root',
})
export class HeaderService {
  isSideNavOpen = true;
  welcomeMessage = '';

  constructor(
    public localizeService: LocalizeRouterService,
    private translateService: TranslateService,
    public router: Router,
    public translateRouterLink: TranslateRouterLinkPipe
  ) {}

  selectLanguage(selectedLang: string) {
    this.localizeService.parser.currentLang = selectedLang;
    this.localizeService.changeLanguage(selectedLang);
    this.translateService.setDefaultLang(selectedLang);
    this.navigateToNewLocalizedRoute(this.router.url);
  }
  navigateToNewLocalizedRoute(routerUrl: string) {
    const linkFragments = routerUrl.split('/');
    linkFragments.splice(1, 1); // remove old locale from url

    // remove existing query strings and fragment as they will be provided through params
    // NOTE: We have to check for query params that use both `;` (used by Angular)
    // and `?` (used by the server or other scripts)
    const newLink = linkFragments
      .join('/')
      .split(';')[0]
      .split('?')[0]
      .split('#')[0];

    // Get params
    const url = window.location.href;
    let queryString = '';
    if (url.indexOf('?') > -1) {
      queryString =
        '?' + (url ? url.split('?').pop() : window.location.search.slice(1));
    }
    // Navigate with params
    this.router.navigateByUrl(
      this.translateRouterLink.transform(newLink + queryString)[0]
    );
  }
}
