import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Output, OnInit } from '@angular/core';
import { HeaderService } from './header.service';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatSelectModule } from '@angular/material/select';
import { TranslateModule } from '@ngx-translate/core';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  standalone: true,
  imports: [
    RouterModule,
    CommonModule,
    MatButtonModule,
    MatDividerModule,
    MatSelectModule,
    TranslateModule,
  ],
})
export class HeaderComponent implements OnInit {
  @Output() toggleNavDrawer: EventEmitter<any> = new EventEmitter();

  today = new Date();
  sessionsPermissions: any;
  selectedLanguage = 'en';

  constructor(
    public headerService: HeaderService,
    public localizeService: LocalizeRouterService
  ) {}

  ngOnInit(): void {
    this.selectedLanguage = this.localizeService.parser.currentLang;
  }

  onToggleSidenav() {
    this.headerService.isSideNavOpen = !this.headerService.isSideNavOpen;
    this.toggleNavDrawer.emit(this.headerService.isSideNavOpen);
  }

  selectLanguage(selectedLang: string) {
    this.headerService.selectLanguage(selectedLang);
  }
}
