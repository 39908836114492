import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatButtonModule } from '@angular/material/button';
import { SupplierOffersService } from './supplier-offers.service';
import { TtSpinnerComponent } from 'src/app/share';
import { MatRadioModule } from '@angular/material/radio';
import { FlatpickrModule } from 'angularx-flatpickr';
import { OnlyNumbersDirective } from 'src/app/core/directives';
import { TranslateModule } from '@ngx-translate/core';
import { HeaderService } from '../header/header.service';
import { ActivatedRoute } from '@angular/router';
import { catchError } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import {
  NgxMaterialTimepickerModule,
  NgxMaterialTimepickerTheme,
} from 'ngx-material-timepicker';

@Component({
  selector: 'app-supplier-offers',
  templateUrl: './supplier-offers.component.html',
  styleUrls: ['./supplier-offers.component.scss'],
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatSelectModule,
    CommonModule,
    MatExpansionModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatButtonModule,
    TtSpinnerComponent,
    MatRadioModule,
    FlatpickrModule,
    OnlyNumbersDirective,
    TranslateModule,
    NgxMaterialTimepickerModule,
  ],
})
export class SupplierOffersComponent {
  supplierOfferForm = new FormGroup({
    lineHaulAmount: new FormControl(''),
    fuelSurchargeAmount: new FormControl(''),
    allInAmount: new FormControl(''),
    currencyId: new FormControl('1'),
    truckEmptyLocation: new FormControl(''),
    truckEmptyTime: new FormControl(''),
    declinedReasonId: new FormControl(''),
    selectedRateType: new FormControl(''),
  });
  shipmentSummary: any;
  isLoading = false;

  formSubmitted = false;
  invalidShipmentToken = false;
  selectedRateType = 1;
  selectedLang = 'en';
  token = '';
  submitError = '';

  darkTheme: NgxMaterialTimepickerTheme = {
    container: {
      bodyBackgroundColor: '#424242',
      buttonColor: '#fff',
    },
    dial: {
      dialBackgroundColor: '#555',
    },
    clockFace: {
      clockFaceBackgroundColor: '#555',
      clockHandColor: '#9fbd90',
      clockFaceTimeInactiveColor: '#fff',
    },
  };

  constructor(
    private supplierOffersService: SupplierOffersService,
    private headerService: HeaderService,
    private activatedRoute: ActivatedRoute,
    private localizeRouterService: LocalizeRouterService
  ) {
    this.headerService.welcomeMessage = 'SUPPLIER_OFFERS.TEXT_WELCOME_MESSAGE';
    this.validateUrl();
  }

  get total() {
    if (this.selectedRateType === 2) {
      return this.supplierOfferForm.get('allInAmount')?.value;
    } else {
      return (
        Number(this.supplierOfferForm.get('lineHaulAmount')?.value ?? 0) +
        Number(this.supplierOfferForm.get('fuelSurchargeAmount')?.value ?? 0)
      );
    }
  }

  validateUrl() {
    this.isLoading = true;
    this.selectedLang = this.localizeRouterService.parser.currentLang;
    this.activatedRoute.queryParams.subscribe(params => {
      this.token = params['token'];
      this.supplierOffersService
        .validateTokenForCarrierQuoteRequest(this.token)
        .pipe(
          catchError((error: any) => {
            this.isLoading = false;
            this.invalidShipmentToken = true;
            throw error;
          })
        )
        .subscribe((response: any) => {
          this.isLoading = false;
          this.shipmentSummary = response;
        });
    });
  }

  onRateTypeChange(value: any) {
    let controls = [
      'allInAmount',
      'lineHaulAmount',
      'fuelSurchargeAmount',
      'truckEmptyLocation',
      'truckEmptyTime',
    ];
    controls.forEach((control: string) => {
      this.supplierOfferForm.get(control)?.clearValidators();
      this.supplierOfferForm.get(control)?.updateValueAndValidity();
    });
  }

  event = {
    onSupplieOfferSubmit: (formValue: any) => {
      if (this.supplierOfferForm.invalid) return;
      let params = {
        token: this.token,
        lineHaulAmount: null,
        fuelSurchargeAmount: null,
        allInAmount: 0,
        currencyId: Number(formValue.currencyId),
        comments: null,
        declinedReasonId: null,
      };

      if (this.selectedRateType === 1) {
        (params.lineHaulAmount as any) = Number(formValue.lineHaulAmount);
        (params.fuelSurchargeAmount as any) = Number(
          formValue.fuelSurchargeAmount
        );
        params.allInAmount =
          (params.fuelSurchargeAmount ?? 0) + (params.lineHaulAmount ?? 0);
        (params.comments as any) =
          formValue.truckEmptyLocation.trim() +
          ' ' +
          formValue.truckEmptyTime.replace(' ', '');
      } else if (this.selectedRateType === 2) {
        params.allInAmount = Number(formValue.allInAmount);
        (params.comments as any) =
          formValue.truckEmptyLocation.trim() +
          ' ' +
          formValue.truckEmptyTime.replace(' ', '');
      } else if (this.selectedRateType === 3) {
        (params.declinedReasonId as any) = Number(formValue.declinedReasonId);
      }
      this.isLoading = true;
      this.supplierOffersService
        .createShipmentLoadOffer(params)
        .subscribe((response: any) => {
          this.isLoading = false;
          if (
            response instanceof HttpErrorResponse ||
            response?.name === 'HttpErrorResponse'
          ) {
            this.submitError = response.error.Message ?? response.message;
          } else {
            this.formSubmitted = true;
          }
        });
    },
  };
}
