<ng-container>
  <app-header></app-header>

  <app-tt-spinner [isLoading]="isLoading"></app-tt-spinner>
  <div class="router-outlet-container" *ngIf="!isLoading">
    <router-outlet></router-outlet>
  </div>

  <app-footer></app-footer>
</ng-container>
