import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-tt-spinner',
  templateUrl: './tt-spinner.component.html',
  styleUrls: ['./tt-spinner.component.scss'],
  standalone: true,
  imports: [CommonModule],
})
export class TtSpinnerComponent {
  @Input() isLoading: boolean = false;
  @Input() message: string = ''; // default message
  @Input() fullBackground = true; // set to false if we dont want background color
  @Input() insideParent = false; // position absolute if it is true. Parent needs to set position to relative
  @Input() paddingTop: string = 'auto';
  @Input() size: string = 'medium';

  get position() {
    // inside parent but no background
    if (!this.fullBackground && !this.insideParent) {
      return 'relative';
    }
    // inside parent with background
    else if (this.insideParent) {
      return 'absolute';
    } else {
      return '';
    }
  }
}
